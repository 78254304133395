import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get CENTER_URL(): string {
    return environment.appUrl + 'center/';
  }

  static get LOGIN(): string {
    return AppUrl.CENTER_URL + 'login';
  }

  static VERIFY_CONTACT(): string {
    return AppUrl.CENTER_URL + 'verify-contact';
  }

  static RESET_PASSWORD(): string {
    return AppUrl.CENTER_URL + 'reset-password';
  }

  static get SELF(): string {
    return AppUrl.CENTER_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.CENTER_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.CENTER_URL + 'logout';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.CENTER_URL + 'file-upload';
  }

  static FILE_UPLOAD_BULK_UPLOAD(): string {
    return AppUrl.CENTER_URL + 'file-upload-bulk-upload';
  }

  static FILE_UPLOAD_BULK_UPLOAD_ZIP(): string {
    return AppUrl.CENTER_URL + 'file-upload-bulk-upload-zip';
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.CENTER_URL + 'update-user-password';
  }

  static COURSES(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'course/' + id;
    } else {
      return AppUrl.CENTER_URL + 'course';
    }
  }

  static CENTER(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'center/' + id;
    } else {
      return AppUrl.CENTER_URL + 'center';
    }
  }

  static CENTER_BRANCH(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'center-branch/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'center-branch';
    }
  }

  static CENTER_BRANCH_PASSWORD(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'center-branch-password/' + id;
    }
  }

  static CENTER_BRANCH_STATUS(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'center-branch-status/' + unique_id;
    }
  }

  static CENTER_BRANCH_AMOUNT(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'center-branch-amount/' + unique_id;
    }
  }

  static CENTER_BRANCH_COUNT(): string {
    return AppUrl.CENTER_URL + 'center-branch-count';
  }

  static CENTER_BRANCH_PROCESSABLE_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processable-amount/' + session_id;
  }

  static CENTER_BRANCH_PROCESSING_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processing-amount/' + session_id;
  }

  static CENTER_BRANCH_PROCESSED_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processed-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSABLE_STUDENT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processable/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSING_STUDENT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processing/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSED_STUDENT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processed/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSABLE_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processable-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSING_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processing-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_PROCESSED_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-student-processed-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_SELF_PROCESSSABLE_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-self-student-processable-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_SELF_PROCESSSING_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-self-student-processing-amount/' + session_id;
  }

  static CENTER_BRANCH_WISE_SELF_PROCESSED_STUDENT_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-wise-self-student-processed-amount/' + session_id;
  }

  static STATES(): string {
    return AppUrl.CENTER_URL + 'states';
  }

  static STUDENT(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'student/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'student';
    }
  }
  static  STUDENT_DATA_BY_ENROLLMENT(): string {
    return AppUrl.CENTER_URL + 'student-data-by-enrollment';
  }
  static STUDENT_IA_AW_MARK(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'student/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'student';
    }
  }

  static CENTER_BRANCHES_STUDENT(): string {
    return AppUrl.CENTER_URL + 'student-center-branches';
  }

  static PROCESS_STUDENT(): string {
    return AppUrl.CENTER_URL + 'process-student';
  }
  static DOCS(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'docs/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'docs';
    }
  }
  static CENTER_CONSIGNMENTS(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'center-consignment/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'center-consignment';
    }
  }
  static BRANCH_CONSIGNMENTS(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'branch-consignment/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'branch-consignment';
    }
  }
  static DOCS_CENTER(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'docs-center/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'docs-center';
    }
  }
  static DOCS_BRANCH(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'docs-branch/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'docs-branch';
    }
  }
  static PROCESS_BRANCH_STUDENT(): string {
    return AppUrl.CENTER_URL + 'process-branch-student';
  }

  static MARK_STUDENT_FOR_PROCESSING(): string {
    return AppUrl.CENTER_URL + 'mark-students-for-processing';
  }

  static MARK_STUDENT_FOR_UN_PROCESSING(): string {
    return AppUrl.CENTER_URL + 'mark-students-for-un-processing';
  }

  static BRANCH_STUDENT(unique_id?): string {
    if (unique_id) {
      return AppUrl.CENTER_URL + 'branch-students/' + unique_id;
    } else {
      return AppUrl.CENTER_URL + 'branch-students';
    }
  }

  static TOTAL_STUDENT_COUNT(id): string {
    return AppUrl.CENTER_URL + 'total-student-count/' + id;
  }

  static FRESH_STUDENT_COUNT(id): string {
    return AppUrl.CENTER_URL + 'fresh-student-count/' + id;
  }

  static LATERAL_ENTRY_STUDENT_COUNT(id): string {
    return AppUrl.CENTER_URL + 'lateral-entry-student-count/' + id;
  }

  static RE_REGISTER_STUDENT_COUNT(id): string {
    return AppUrl.CENTER_URL + 're-register-student-count/' + id;
  }

  static ADD_STUDENT(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'add-student/' + id;
    } else {
      return AppUrl.CENTER_URL + 'add-student';
    }
  }

  static RE_REGISTER_STUDENT(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 're-register-student/' + id;
    } else {
      return AppUrl.CENTER_URL + 're-register-student';
    }
  }

  static RE_REGISTER_BRANCH_STUDENT(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 're-register-branch-student/' + id;
    } else {
      return AppUrl.CENTER_URL + 're-register-branch-student';
    }
  }

  static BACK_EXAM_STUDENTS(): string {
    return AppUrl.CENTER_URL + 'back-exam-student';
  }

  static BRANCH_MARKED_BACK_EXAM_STUDENTS(): string {
    return AppUrl.CENTER_URL + 'branch-marked-back-exam-student';
  }

  static MARK_STUDENT_BACK_EXAM(id?): string {
    return AppUrl.CENTER_URL + 'mark-student-back-exam/' + id;
  }

  static UPDATE_MARKED_STUDENT_BACK_EXAM_STATUS(id?): string {
    return AppUrl.CENTER_URL + 'update-marked-student-back-exam-status/' + id;
  }

  static ADD_BULK_STUDENT(id): string {
    if (id) {
      return AppUrl.CENTER_URL + 'add-bulk-student/' + id;
    }
  }

  static RESEND_OTP_STUDENT(): string {
    return AppUrl.CENTER_URL + 'resend-otp-student';
  }

  static VERIFY_OTP_CENTER(): string {
    return AppUrl.CENTER_URL + 'verify-otp-center';
  }

  static STUDENT_DETAILS_BULK_UPLOAD(session_id?): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'student-bulk-upload/' + session_id;
    }
  }

  static SESSIONS(): string {
    return AppUrl.CENTER_URL + 'session';
  }

  static SESSION_WITH_SHARE_PERCENTAGE(): string {
    return AppUrl.CENTER_URL + 'session-with-share-percentage';
  }

  static DEFAULT_SESSION(): string {
    return AppUrl.CENTER_URL + 'default-session';
  }

  static GET_BRANCH_SHARE_PERCENTAGE(): string {
    return AppUrl.CENTER_URL + 'branch-share-percentage';
  }

  static UPDATE_BRANCH_SHARE_PERCENTAGE(user_id, session_id): string {
    return AppUrl.CENTER_URL + 'update-branch-share-percentage/' + user_id + '/' + session_id;
  }

  static DOWNLOAD_BRANCH_REPORT(sessionId): string {
    return AppUrl.CENTER_URL + 'download-branch-report/' + sessionId;
  }

  static PERSONAL_INFO(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'personal-info/' + id;
    } else {
      return AppUrl.CENTER_URL + 'personal-info';
    }
  }

  static TRANSLATE_TEXT(): string {
    return AppUrl.CENTER_URL + 'translate-text';
  }

  static PERSONAL_INFO_PHOTOGRAPH_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'personal-info-photograph/' + id;
    }
  }

  static PERSONAL_INFO_SIGNATURE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'personal-info-signature/' + id;
    }
  }

  static PERSONAL_INFO_GOVT_PHOTO_ID_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'personal-info-govt-photo-id-proof/' + id;
    }
  }

  static ADDRESS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'address/' + id;
    } else {
      return AppUrl.CENTER_URL + 'address';
    }
  }

  static ACADEMIC_DETAILS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'academic-details/' + id;
    } else {
      return AppUrl.CENTER_URL + 'academic-details';
    }
  }

  static ACADEMIC_DETAILS_TENTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'academic-details-tenth-certificate/' + id;
    }
  }

  static ACADEMIC_DETAILS_TWELTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'academic-details-twelth-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'more-qualification/' + id;
    } else {
      return AppUrl.CENTER_URL + 'more-qualification';
    }
  }

  static MORE_QUALIFICATION_PREVIOUS_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'more-qualification-previous-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'more-qualification-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_POST_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'more-qualification-post-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_OTHER_QUALIFICATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'more-qualification-other-qualification-certificate/' + id;
    }
  }

  static PROGRAMS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'program/' + id;
    } else {
      return AppUrl.CENTER_URL + 'program';
    }
  }

  static PCB_MAPPINGS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'pcb-mapping/' + id;
    } else {
      return AppUrl.CENTER_URL + 'pcb-mapping';
    }
  }

  static COURSE_DETAILS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'course-details/' + id;
    } else {
      return AppUrl.CENTER_URL + 'course-details';
    }
  }

  static COURSE_CURRICULUM(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'course-curriculum/' + id;
    } else {
      return AppUrl.CENTER_URL + 'course-curriculum';
    }
  }

  static PROFILE_IMAGE(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'profile-image/' + id;
    } else {
      return AppUrl.CENTER_URL + 'profile-image';
    }
  }

  static SELF_PROFILE_PASSWORD(): string {
    return AppUrl.CENTER_URL + 'update-user-password';
  }

  static NOTIFICATION(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'notify/' + id;
    } else {
      return AppUrl.CENTER_URL + 'notify';
    }
  }

  static NOTIFICATION_STATUS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'notify-status/' + id;
    }
  }

  static NOTIFICATION_SELF(): string {
    return AppUrl.CENTER_URL + 'notify-self';
  }

  static NOTIFICATION_COUNT(): string {
    return AppUrl.CENTER_URL + 'notify-count';
  }

  static SUPPORT_TICKET(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'support-ticket/' + id;
    } else {
      return AppUrl.CENTER_URL + 'support-ticket';
    }
  }

  static SUPPORT_TICKET_COUNT(): string {
    return AppUrl.CENTER_URL + 'ticket-count';
  }

  static SUPPORT_TICKET_REPORT_COUNT(): string {
    return AppUrl.CENTER_URL + 'support-ticket-report-count';
  }

  static RECEIPTS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'receipt/' + id;
    } else {
      return AppUrl.CENTER_URL + 'receipt';
    }
  }

  static RECEIPTS_STATUS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'receipt-status/' + id;
    } else {
      return AppUrl.CENTER_URL + 'receipt-status';
    }
  }

  static FORWARD_RECEIPT(): string {
    return AppUrl.CENTER_URL + 'forward-receipt';
  }

  static CREDIT_TRANSACTIONS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'credit-transaction/' + id;
    } else {
      return AppUrl.CENTER_URL + 'credit-transaction';
    }
  }

  static BRANCH_CREDIT_TRANSACTIONS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'branch-credit-transaction/' + id;
    } else {
      return AppUrl.CENTER_URL + 'branch-credit-transaction';
    }
  }

  static CENTER_CREDIT_TRANSACTIONS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'center-credit-transaction/' + id;
    } else {
      return AppUrl.CENTER_URL + 'center-credit-transaction';
    }
  }

  static BRANCH_RECEIPTS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'branch-receipt/' + id;
    } else {
      return AppUrl.CENTER_URL + 'branch-receipt';
    }
  }

  static RECEIPTS_COUNT(): string {
    return AppUrl.CENTER_URL + 'receipts-count';
  }

  static RECEIPTS_BANKS(): string {
    return AppUrl.CENTER_URL + 'banks';
  }

  static RECEIPTS_TOTAL(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-total/' + session_id;
    }
  }

  static RECEIPTS_PENDING(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-pending/' + session_id;
    }
  }

  static RECEIPTS_ACCEPTED(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-accepted/' + session_id;
    }
  }

  static RECEIPTS_REJECTED(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-rejected/' + session_id;
    }
  }

  static RECEIPTS_BRANCH_TOTAL(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-branch-total/' + session_id;
    }
  }

  static RECEIPTS_BRANCH_PENDING(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-branch-pending/' + session_id;
    }
  }

  static RECEIPTS_BRANCH_ACCEPTED(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-branch-accepted/' + session_id;
    }
  }

  static RECEIPTS_BRANCH_REJECTED(session_id): string {
    if (session_id) {
      return AppUrl.CENTER_URL + 'receipt-branch-rejected/' + session_id;
    }
  }

  static CREDIT_TRANSACTIONS_TOTAL(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-total';
  }

  static CREDIT_TRANSACTIONS_CREDITED(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-credited';
  }

  static CREDIT_TRANSACTIONS_DEBITED(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-debited';
  }

  static CREDIT_TRANSACTIONS_BRANCH_TOTAL(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-branch-total';
  }

  static CREDIT_TRANSACTIONS_BRANCH_CREDITED(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-branch-credited';
  }

  static CREDIT_TRANSACTIONS_BRANCH_DEBITED(): string {
    return AppUrl.CENTER_URL + 'credit-transaction-branch-debited';
  }

  static STUDENT_PENDING_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-pending-count/' + session_id;
  }

  static STUDENT_PROCESSABLE_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processable-count/' + session_id;
  }

  static STUDENT_PROCESSING_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processing-count/' + session_id;
  }

  static STUDENT_PROCESSED_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processed-count/' + session_id;
  }

  static STUDENT_PROCESSABLE_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processable-amount/' + session_id;
  }

  static STUDENT_PROCESSING_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processing-amount/' + session_id;
  }

  static STUDENT_PROCESSED_AMOUNT(session_id): string {
    return AppUrl.CENTER_URL + 'student-processed-amount/' + session_id;
  }

  static STUDENT_BRANCH_PENDING_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-pending-count/' + session_id;
  }

  static STUDENT_BRANCH_PROCESSABLE_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processable-count/' + session_id;
  }

  static STUDENT_BRANCH_PROCESSING_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processing-count/' + session_id;
  }

  static STUDENT_BRANCH_PROCESSED_COUNT(session_id): string {
    return AppUrl.CENTER_URL + 'branch-student-processed-count/' + session_id;
  }

  static DOWNLOAD_STUDENT(): string {
    return AppUrl.CENTER_URL + 'download-students';
  }

  static DOWNLOAD_RE_REGISTERED_STUDENT(): string {
    return AppUrl.CENTER_URL + 'download-center-re-registered-students';
  }

  static DOWNLOAD_CENTER_BRANCH_RE_REGISTERED_STUDENT(): string {
    return AppUrl.CENTER_URL + 'download-center-branch-re-registered-students';
  }

  static TRANSFERRED_STUDENT(): string {
    return AppUrl.CENTER_URL + 'transferred-student';
  }

  static TRANSFERRED_BRANCH_STUDENT(): string {
    return AppUrl.CENTER_URL + 'transferred-branch-student';
  }

  static TRANSFER_STUDENT_STATUS(id): string {
    return AppUrl.CENTER_URL + 'transferred-student-status/' + id;
  }

  static LEADS(id?): string {
    if (id) {
      return AppUrl.CENTER_URL + 'lead/' + id;
    } else {
      return AppUrl.CENTER_URL + 'lead';
    }
  }
}
