import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'general',
    loadChildren: () => import('../../components/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('../../components/branches/branches.module').then(m => m.BranchesModule)
  },
  {
    path: 'students',
    loadChildren: () => import('../../components/students/students.module').then(m => m.StudentsModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('../../components/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('../../components/exam/exam.module').then(m => m.ExamModule)
  },
  /*
  {
    path: 'results',
    loadChildren: () => import('../../components/result/result.module').then(m => m.ResultModule)
  },
  */
  {
    path: 'accounts',
    loadChildren: () => import('../../components/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../components/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'notify',
    loadChildren: () => import('../../components/notify/notify.module').then(m => m.NotifyModule)
  }
];
