<div class="authentication-main">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'LOGIN_OTP'">Login OTP</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'LOGIN_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="centerLoginOtpForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <input class="form-control" type="number" formControlName="otp"
                               placeholder="Enter OTP">
                        <small *ngIf="submittedOtp && centerLoginOtpForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                <small>Resend OTP in - </small>
                <small class="text-danger mr-1"
                       *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
                <small class="text-primary cursor-pointer"
                       *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="contactVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Contact No.</label>
                        <input class="form-control" type="number" formControlName="contact"
                               placeholder="Enter Contact">
                        <small *ngIf="submittedContactVerify && contactVerifyForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Contact</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword"
                               formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'LOGIN_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyContact()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-md-12">
      <div class="auth-innerright">
        <div class="authentication-box">
          <div class="mt-4">
            <div class="card-header" style="background: transparent; border-bottom: none ; margin-bottom: 20px">
              <div class="main-header-left text-center">
                <div class="logo-wrapper">
                  <img src="assets/images/logo/em_logo.webp" alt="Admission Management System" style="height: 70px">
                </div>
              </div>
            </div>
            <div class="card-body px-0">
              <div class="cont text-center b-light" [ngClass]="{'s--signup': signup}">
                <div>
                  <form class="theme-form" [formGroup]="loginForm">
                    <div class="row mb-lg-3">
                      <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-md-3">
                        <h4>WELCOME BACK</h4>
                        <h6>Sign In to your account and start exploring</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group text-left mt-30px">
                          <label class="col-form-label pt-0">Username</label>
                          <input class="form-control border-radius-0" type="text" placeholder="volmint"
                                 formControlName="username"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group text-left">
                          <label class="col-form-label">Password</label>
                          <input class="form-control border-radius-0" type="password" placeholder="*******"
                                 formControlName="password"/>
                        </div>
                      </div>
                    </div>
                    <div class="form-group text-left text-info mt-55px">
                      <div class="row">
                        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                          <div class="row mb-30px">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <div class="checkbox p-0 text-left">
                                <input id="checkbox1" type="checkbox">
                                <label for="checkbox1" class="f_12">
                                  <small>Remember me</small>
                                </label>
                              </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 text-left">
                              <h6 class="col-form-label f_12 cursor-pointer mt-5px"
                                  (click)="openForgotPasswordModal(content)">Forgot Password ?</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   <div class="row justify-content-center">
                     <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-8">
                       <div class="form-group form-row m-t-3" style="">
                         <button class="btn btn-primary text-center" type="submit" (click)="signIn()">Sign In</button>
                       </div>
                     </div>
                   </div>
                    <div class="social mt-3 d-block d-md-none height-150px">
                    </div>
                  </form>
                </div>
                <div class="sub-cont" style="left: 487px;">
                  <div class="img">
                    <div class="img__text m--up">
                      <!-- <h2>Education Mitra</h2>-->
                    </div>
                    <div class="img__text m--in">
                      <h2 style="color: black!important;">Already Registered ?</h2>
                      <p>Sign In to your account and start exploring !</p>
                    </div>
                    <!--<div class="img__btn" (click)=toggle()>
                      <span class="m&#45;&#45;up">Welcome Center</span>
                      <span class="m&#45;&#45;in">Sign in</span>
                    </div>-->
                    <!--<div class="img__btn">
                      <span class="m&#45;&#45;up">Welcome</span>
                    </div>-->
                  </div>
                  <!--<div>
                    <form class="theme-form text-left" [formGroup]="registrationForm">
                      <h4 class="text-center">Register Now</h4>
                      <h6 class="text-center">Enter your details and choose course</h6>
                      <div class="form-row mt-20px">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Full Name"
                                   formControlName="name"/>
                            <small *ngIf="submitted && registrationForm.get('name').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Email"
                                   formControlName="email"/>
                            <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="number" placeholder="Contact"
                                   formControlName="contact"/>
                            <small *ngIf="submitted && registrationForm.get('contact').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <select class="form-control border-radius-0" formControlName="course_id">
                          <option value="">Select Course</option>
                          <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('course_id').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                      <div class="checkbox p-0 text-left">
                        <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions"
                               [ngModelOptions]="{standalone: true}">
                        <label for="checkbox2" class="f_12">
                          <small>I agree to terms & conditions</small>
                        </label>
                      </div>
                      <div class="form-row">
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-block" type="submit" (click)="register()">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
