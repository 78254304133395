<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
     <!-- <p class="mb-0">Copyright © {{date}} <span class="text-info cursor-pointer" (click)="open('https://www.volmint.com')">Volmint Technologies</span>.
        All rights reserved.</p>-->
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0">Hand-crafted & made with<i class="fa fa-heart"></i></p>
    </div>
  </div>
</div>
