import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.STUDENT(), data);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(), data);
  }

  getReRegisterStudents(data): Observable<any> {
    return this.getRequest(AppUrl.RE_REGISTER_STUDENT(), data);
  }

  getBackExamStudents(data): Observable<any> {
    return this.getRequest(AppUrl.BACK_EXAM_STUDENTS(), data);
  }

  getBranchMarkedBackExamStudents(data): Observable<any> {
    return this.getRequest(AppUrl.BRANCH_MARKED_BACK_EXAM_STUDENTS(), data);
  }

  marKStudentBackExam(id, data): Observable<any> {
    return this.postRequest(AppUrl.MARK_STUDENT_BACK_EXAM(id), data);
  }

  updateMarkedStudentBackExamStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_MARKED_STUDENT_BACK_EXAM_STATUS(id), data);
  }

  getReRegisterBranchStudents(data): Observable<any> {
    return this.getRequest(AppUrl.RE_REGISTER_BRANCH_STUDENT(), data);
  }

  getCenterBranchesStudents(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCHES_STUDENT(), data);
  }

  getBranchStudents(data): Observable<any> {
    return this.getRequest(AppUrl.BRANCH_STUDENT(), data);
  }

  getTotalStudentCount(id): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_STUDENT_COUNT(id));
  }

  getFreshStudent(id): Observable<any> {
    return this.getRequest(AppUrl.FRESH_STUDENT_COUNT(id));
  }

  getLateralEntryStudent(id): Observable<any> {
    return this.getRequest(AppUrl.LATERAL_ENTRY_STUDENT_COUNT(id));
  }

  getReRegisterStudent(id): Observable<any> {
    return this.getRequest(AppUrl.RE_REGISTER_STUDENT_COUNT(id));
  }

  addStudent(data): Observable<any> {
    return this.postRequest(AppUrl.ADD_STUDENT(), data);
  }

  reRegisterStudent(data): Observable<any> {
    return this.postRequest(AppUrl.RE_REGISTER_STUDENT(), data);
  }

  reRegisterBranchStudent(data): Observable<any> {
    return this.postRequest(AppUrl.RE_REGISTER_BRANCH_STUDENT(), data);
  }

  addBulkStudent(id, data): Observable<any> {
    return this.postRequest(AppUrl.ADD_BULK_STUDENT(id), data);
  }

  detail(unique_id, data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(unique_id), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT(id), data);
  }

  updateIaAwMark(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_IA_AW_MARK(id), data);
  }

  resendOTP(data): Observable<any> {
    return this.postRequest(AppUrl.RESEND_OTP_STUDENT(), data);
  }

  pendingStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PENDING_COUNT(session_id));
  }

  processableStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSABLE_COUNT(session_id));
  }

  processingStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSING_COUNT(session_id));
  }

  processedStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSED_COUNT(session_id));
  }

  processableStudentAmount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSABLE_AMOUNT(session_id));
  }

  processingStudentAmount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSING_AMOUNT(session_id));
  }

  processedStudentAmount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_PROCESSED_AMOUNT(session_id));
  }

  branchPendingStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_BRANCH_PENDING_COUNT(session_id));
  }

  branchProcessableStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_BRANCH_PROCESSABLE_COUNT(session_id));
  }

  branchProcessingStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_BRANCH_PROCESSING_COUNT(session_id));
  }

  branchProcessedStudentCount(session_id): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.STUDENT_BRANCH_PROCESSED_COUNT(session_id));
  }

  downloadStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_STUDENT(), data);
  }

  downloadReRegisteredStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_RE_REGISTERED_STUDENT(), data);
  }

  downloadCenterBranchReRegisteredStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_CENTER_BRANCH_RE_REGISTERED_STUDENT(), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.STUDENT(id));
  }
  getDataByEnrollmentNo(data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_DATA_BY_ENROLLMENT(), data);
  }
}
